import {FilterSearch} from "../types/types";
import PluginOptions from "../types/PluginOptions";

export default class FilterUtil {
    public static getDefaultFilters(): FilterSearch[] {
        return [
            FilterUtil.getCountryFilter("Griekenland", "GR"),
            FilterUtil.getCountryFilter("Turkije", "TR"),
            FilterUtil.getCountryFilter("Spanje", "ES"),
            FilterUtil.getCountryFilter("Italië", "IT"),
            FilterUtil.getCountryFilter("Portugal", "PT"),
            FilterUtil.getCountryFilter("Kroatië", "HR"),
        ];
    }

    public static removeDuplicates(filters: FilterSearch[]): FilterSearch[] {
        return filters.filter(
            (filter, index, self) => {
                return self.findIndex(f => f.value === filter.value && f.filterType === filter.filterType) === index
            });
    }

    public static removeUnderScores(filters: FilterSearch[]): FilterSearch[] {
        return filters.map(filter => {
            if (filter.filterType.includes("_")) {
                const [type, subType] = filter.filterType.split("_");
                return {
                    ...filter,
                    filterType: type,
                    filterSubCategory: subType
                }
            }
            return filter;
        });
    }

    public static parsePluginOptionsToFilters(pluginOptions: PluginOptions): FilterSearch[] {
        const filters: FilterSearch[] = [];

        if(!pluginOptions) return filters;

        const parseCategory = (category: string, pluginFilters: any) => {
            if (!pluginFilters) return;

            Object.keys(pluginFilters).forEach(key => {
                if (typeof pluginFilters[key] === "object") {
                    Object.keys(pluginFilters[key]).forEach(subKey => filters.push({
                        filterCategory: category,
                        filterType: key,
                        filterSubCategory: subKey,
                        value: pluginFilters[key][subKey]
                    }));
                } else {
                    filters.push({
                        filterCategory: category,
                        filterType: key,
                        value: pluginFilters[key]
                    });
                }
            });
        }



        if(pluginOptions.accommodation) {
            parseCategory("accommodation", pluginOptions.accommodation);
        }
        if(pluginOptions.vacation) {
            parseCategory("vacation", pluginOptions.vacation);
        }

        return filters;
    }

    private static getCountryFilter(name: string, code: string): FilterSearch {
        return {
            filterCategory: "accommodation",
            filterType: "country",
            displayValue: name,
            value: code
        }
    }
}
