import {FilterSearch, SortOptions, UrlFilter} from "../types/types";
import {FilterCategory} from "./RangeUtil";

type UrlRange = {
    min: number | string;
    max: number | string;
}

export default class UrlUtil {

    /**
     * Adds filters as correctly formatted parameters to a URL.
     *
     * @param {string} url - The base URL to which parameters will be appended.
     * @param {Array<FilterSearch>} selectedFilters - An array of filters to be parsed into URL parameters.
     * @param {Array<FilterCategory>} exceptions - An array of filter types that should not be included in the URL.
     *
     * @returns {string} - The URL with formatted parameters, including all specified filters.
     */

    public static formatUrlParams(url: string = "", selectedFilters: FilterSearch[], exceptions: FilterCategory[] = []): string {
        let urlParams: string = "";
        if (selectedFilters.length > 0) {
            urlParams = urlParams.concat("?")
            selectedFilters.forEach((filter, key) => {
                if (UrlUtil.isException(exceptions, filter)) return;

                if (key !== 0) {
                    urlParams = urlParams.concat("&");
                }
                urlParams = urlParams.concat(`filter[${filter.filterCategory}][${filter.filterType}]${filter.filterSubCategory ? `[${filter.filterSubCategory}]` : ""}[]=${filter.value}`);
            })
        }

        return url.concat(urlParams)
    }

    /**
     * Adds a filter parameter to a URL based on a range with minimum and maximum values.
     *
     * @param {string} url - The base URL to which the parameters will be added.
     * @param {FilterSearch} filter - The filter that needs to be added to URL
     * @param {UrlRange} range - An object containing a minimum and maximum value to determine the range.
     *
     * @returns {string} - The updated URL with the range filter parameters added.
     *
     * @example
     * // Example usage:
     * const baseUrl = "https://example.com/vacation?filter[accommodation][country][]=NL";
     * const filter = { filterCategory: "accommodation", filterType: "country", value: "NL"}
     * const range = { min: 5, max: 7 };
     * const updatedUrl = addRangeToUrlParams(baseUrl, category, type, range);
     * console.log(updatedUrl);
     * // Outputs: "https://example.com/vacation?filter[accommodation][country][]=NL&filter[vacation][duration][min]=5&filter[vacation][duration][max]=7"
     */
    public static addRangeToUrlParams(url: string, filter: FilterSearch, range: UrlRange): string {
        url.includes("?") ? url = url.concat("&") : url = url.concat("?");
        url = url.concat(`filter[${filter.filterCategory}][${filter.filterType}]${filter.filterSubCategory ? `[${filter.filterSubCategory}]` : ""}[min]=${range.min}`);
        return url.concat(`&filter[${filter.filterCategory}][${filter.filterType}]${filter.filterSubCategory ? `[${filter.filterSubCategory}]` : ""}[max]=${range.max}`);
    }

    public static addParticipantAmountToUrlParams(url: string, participantAmount: number): string {
        url.includes("?") ? url = url.concat("&") : url = url.concat("?");
        return url.concat(`participants=${participantAmount}`);
    }

    public static addSortingToUrlParams(url: string, sortOptions: SortOptions): string {
        url.includes("?") ? url = url.concat("&") : url = url.concat("?");
        return url.concat(`sort=${sortOptions.name}&sortDirection=${sortOptions.sortDirection}`);
    }

    public static parseUrlParamsToFilters(urlParams: string): FilterSearch[] {
        try {
            return JSON.parse(atob(urlParams)).map((filter: UrlFilter) => {
                return {
                    filterCategory: filter.cat === "a" ? "accommodation" : "vacation",
                    filterSubCategory: filter.subCat,
                    filterType: filter.type,
                    value: filter.val,
                    displayValue: filter.display,
                    count: filter.count
                }
            });
        } catch (e) {
            return []
        }
    }

    public static parseFiltersToUrlParams(filters: FilterSearch[]): string {
        const urlFilters: UrlFilter[] = filters.map((filter) => {
            return {
                cat: filter.filterCategory === "accommodation" ? "a" : "v",
                subCat: filter.filterSubCategory,
                type: filter.filterType,
                val: filter.value,
                display: filter.displayValue,
                count: filter.count
            }
        });

        return btoa(JSON.stringify(urlFilters));
    }

    private static isException(exceptions: FilterCategory[], filter: FilterSearch): boolean {
        return exceptions.findIndex((e) => {
            const subCategoryExists: boolean = (
                filter.filterSubCategory !== undefined ||
                e.filterSubCategory !== undefined
            );
            return filter.filterType === e.filterType && (subCategoryExists ? filter.filterSubCategory === e.filterSubCategory : true);
        }) > -1;
    }
}