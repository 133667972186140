import {apiService} from "./ApiService";
import {Filter, FilterSearch} from "../types/types";
import UrlUtil from "../utils/UrlUtil";
import RangeUtil from "../utils/RangeUtil";
import FilterUtil from "../utils/FilterUtil";
import PluginOptions from "../types/PluginOptions";

class FilterService {
    public getFilters(selectedFilters: FilterSearch[], urlParams: string, pluginOptions: PluginOptions | undefined = undefined): Promise<Filter[]> {
        return new Promise((resolve, reject) => {
            selectedFilters = [...selectedFilters, ...UrlUtil.parseUrlParamsToFilters(urlParams)]

            if (pluginOptions !== undefined) {
                const pluginFilters: FilterSearch[] = FilterUtil.parsePluginOptionsToFilters(pluginOptions);
                pluginFilters.forEach((filter) => {
                    if (!selectedFilters.some((f) => f.filterType === filter.filterType && f.filterCategory === filter.filterCategory && f.filterSubCategory === filter.filterSubCategory)) {
                        selectedFilters.push(filter);
                    }
                });
            }

            selectedFilters = FilterUtil.removeDuplicates(selectedFilters);
            selectedFilters = FilterUtil.removeUnderScores(selectedFilters);

            let url: string = UrlUtil.formatUrlParams("filter/receive", selectedFilters, [
                {filterType: "all_countries", filterSubCategory: "default"},
                {filterType: "all_types", filterSubCategory: "default"}
                , ...RangeUtil.RANGE_FILTERS
            ]);

            url = RangeUtil.addRangeFiltersToUrl(selectedFilters, url);

            apiService.call<Filter[]>(url)
                .then(response => {
                    return resolve(response.data);
                });
        });
    }
}

export const filterService: FilterService = new FilterService();