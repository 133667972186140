import React, {useState} from "react";
import {Carousel} from "react-bootstrap";
import "./TravelPackageItem.scss";
import {
    LeftArrow,
    LocationIcon,
    RightArrow,
    ShareIcon, Star,
} from "../../../Icons";
import {Vacation} from "../../../types/types";
import {Provider} from "../../../types/provider/Provider";
import flightImage from "./../../../assets/img/flight.svg";
import tuiLogo from "./../../../assets/img/tui_logo.svg";
import sunwebLogo from "./../../../assets/img/sunweb_logo.png";
import djivLogo from "./../../../assets/img/djiv_logo.png";
import elizaWasHereLogo from "./../../../assets/img/eliza_was_here_logo.png";
import d_reizenLogo from "./../../../assets/img/d_reizen_logo.png";
import corendonLogo from "./../../../assets/img/corendon_logo.png";
import lavidaTravelLogo from "./../../../assets/img/lavida_travel_logo.png";
import thomasCookLogo from "./../../../assets/img/thomas_cook_logo.png";
import jet2holidaysLogo from "./../../../assets/img/jet2holidays_logo.svg";

interface TravelPackageItemProps {
    item: Vacation;
    trpackage?: string;
}

const TravelPackageItem: React.FC<TravelPackageItemProps> = ({
                                                                 item,
                                                                 trpackage,
                                                             }) => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };
    let departureDate: string;
    let departureDateReturn: string;
    if (item.departureDate === null) {
        departureDate = "Geen datum beschikbaar";
        departureDateReturn = "Geen datum beschikbaar";
    } else {

        departureDate = new Date(item.departureDate * 1000).toLocaleDateString('nl-NL', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });

        departureDateReturn = new Date(item.departureDate * 1000 + (item.duration*24*60*60)*1000).toLocaleDateString('nl-NL', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    }

    const renderLogo = (provider: string) => {
        switch (provider) {
            case Provider.TUI:
                return <img src={tuiLogo} alt="TUI Logo"/>;
            case Provider.SUNWEB:
                return <img src={sunwebLogo} alt="Sunweb Logo"/>;
            case Provider.DE_JONG_INTRA:
                return <img src={djivLogo} alt="De Jong Intra Logo"/>;
            case Provider.ELIZA_WAS_HERE:
                return <img src={elizaWasHereLogo} alt="Eliza Was Here Logo"/>;
            case Provider.D_REIZEN:
                return <img src={d_reizenLogo} alt="D-Reizen Logo"/>;
            case Provider.THOMAS_COOK:
                return <img src={thomasCookLogo} alt="Thomas Cook Logo"/>;
            case Provider.CORENDON:
                return <img src={corendonLogo} alt="Corendon Logo"/>;
            case Provider.LAVIDA_TRAVEL:
                return <img src={lavidaTravelLogo} alt="Lavida Travel Logo"/>;
            default:
                // @todo replace this placeholder image
                return <img src={jet2holidaysLogo} alt="Jet2Holidays Logo"/>;
        }
    }

    /// @todo remove hotfix, replace with proper translation in backend
    // if (item.priceType === "per person") {
    //     item.priceType = "per persoon";
    // }
    /// @todo end hotfix

    const renderStarIcons = (value: number) => {
        return (
            <div className="d-flex align-items-center gap-1">
                <Star color={value === null ? "#CBD5E1" : "#FB923C"}/>
                <Star color={value < 2 ? "#CBD5E1" : "#FB923C"}/>
                <Star color={value < 3 ? "#CBD5E1" : "#FB923C"}/>
                <Star color={value < 4 ? "#CBD5E1" : "#FB923C"}/>
                <Star color={value < 5 ? "#CBD5E1" : "#FB923C"}/>
            </div>
        );
    }

    const renderImage = (images: string[]) => {
        if(!images || images.length === 0) {
            return (
                <img
                    className="d-block w-100 slide-img default-img"
                    src="/assets/img/place/img2.png"
                    alt={`Slide`}
                    loading={"lazy"}
                />
            );
        }

        if (images.length === 1) {
            return (
                <img
                    className="d-block w-100 slide-img"
                    src={images[0]}
                    alt={`Slide`}
                    loading={"lazy"}
                />
            );
        }

        return (
            <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                prevIcon={<LeftArrow/>}
                nextIcon={<RightArrow/>}
            >
                {images.map((image, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100 slide-img"
                            src={image}
                            alt={`Slide`}
                            loading={"lazy"}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        )
    }

    return (
        <div id="travel-package-item">
            <div className="travel-package-content-wrapper">
                <div className="">
                    {renderImage(item.images)}
                </div>
                <div className="d-flex align-items-start">
                    <div style={{width: "100%"}}>
                        <div className="d-flex justify-content-between align-items-center" style={{padding: "5px 0px"}}>
                            <div data-testid="stars" className="rating-box" title={item.accommodationStars ? `${item.accommodationStars} Sterren` : "Geen sterren beschikbaar"}>
                                {renderStarIcons(item.accommodationStars)} <span style={{margin: "0px 0px 0px 6px"}}>Sterren</span>
                            </div>
                            <button className="share-btn">
                                Delen <ShareIcon/>
                            </button>
                        </div>
                        <h2>{item.accommodationName}</h2>
                        <h3>
                            <LocationIcon/>
                            {item.city}, {item.region}, {item.country}
                        </h3>
                    </div>
                </div>
            </div>

            <div className="bottom-contnets">
                <div className="box-item">
                    <div className="img-side">
                        <div className="img-content">
                            {renderLogo(item.provider.toLowerCase())}
                        </div>

                        <div className="half-circle1"/>
                        <div className="half-circle2"/>
                    </div>
                    <div className="text-side">
                        <div className="text-item">
                            <h6>{item.provider}</h6>
                            <h5>{item.accommodationType} ({item.duration} dagen)</h5>
                            <h6>{item.transportType}</h6>
                        </div>

                        <div className="d-flex align-item-center gap-1" style={{width: "65%", justifyContent: "space-between"}}>
                            <div className="text-item">
                                <h5>{departureDate}</h5>
                                <h6>{item.departureAirport ?? "Vertrek datum"}</h6>
                            </div>
                            <div className="img-item plane-img">
                                <img src={flightImage} alt="" />
                            </div>
                            <div className="text-item departure-return">
                                <h5>{departureDateReturn}</h5>
                                <h6>{item.departureAirport ?? "Terugreis"}</h6>
                            </div>

                            <div className="text-item">
                                <h3>&euro; {item.price}</h3>
                                <h6>{item.priceType ?? "per persoon"}</h6>
                            </div>
                            <a href={item.vacationUrl} target="_blank" rel="noopener noreferrer" data-testid={"more-info-btn"}>
                                <button className="view-details-btn">Meer informatie</button>
                            </a>
                        </div>
                    </div>
                    <div className="text-side-sm-device w-100">
                        <div className="d-flex justify-content-between w-100">
                            <div className="text-item">
                                <h6>{item.provider}</h6>
                                <h5 className="break-word">{item.accommodationName}</h5>
                                <h6>{item.transportType}</h6>
                            </div>
                            <div className="text-item">
                                <h3>&euro; {item.price}</h3>
                                <h6>{item.priceType ?? "per persoon"}</h6>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <div className="text-item">
                                <h6>{item?.duration} dagen</h6>
                                    <h5>
                                        {departureDate}
                                    </h5>
                                </div>
                            </div>

                            <a href={item.vacationUrl} target="_blank" rel="noopener noreferrer">
                                <button className="view-details-btn">Meer informatie</button>
                            </a>
                        </div>

                    </div>
                </div>

                {/* @todo implement see more functionality in backend */}
                {/*{trpackage === "search" && <button className="more-btn">2 Meer</button>}*/}
        </div>
    );
};

export default TravelPackageItem;
