import PluginOptions from "../types/PluginOptions";
import {SET_PLUGIN_OPTIONS} from "../actions";
import {ActionWithPayload} from "../types/redux/ActionWithPayload";

export interface PluginReducerState {
    pluginOptions: PluginOptions | null;
}

export const initialState: PluginReducerState = {
    pluginOptions: null
};

const PluginReducer = (state: PluginReducerState = initialState, action: ActionWithPayload<any>): PluginReducerState => {
    switch (action.type) {
        case SET_PLUGIN_OPTIONS:
            return {
                ...state,
                pluginOptions: action.payload
            }
        default:
            return state;
    }
}

export default PluginReducer;